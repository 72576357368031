<div class="layout">
    <div class="header --subpage" [ngClass]="{ '--has-banner': !isSubscribed }">
        <div
            class="header__profile-menu"
            *ngIf="users?.length"
            (click)="showProfileModal()"
            [style.backgroundImage]="'url(' + users[0]?.getAvatarUrl() + ')'"></div>
        <div class="header__title">{{ title }}</div>
        <div class="header__action-box">
            <ng-container>
                <img
                    *ngIf="!hasUnreadInboxMessage"
                    (click)="openInboxModal()"
                    src="/assets/notification.svg"
                    class="header__action-box__notification-icon" />
            </ng-container>
            <ng-container>
                <img
                    *ngIf="hasUnreadInboxMessage"
                    (click)="openInboxModal()"
                    src="/assets/notification-unread.svg"
                    class="header__action-box__notification-icon" />
            </ng-container>
            <ng-container>
                <img (click)="showHelpModal()" src="/assets/question-mark.svg" class="header__action-box__help-icon" />
            </ng-container>
            <!-- <ng-container>
                <button class="button-v2 --small --purple --rounded" *ngIf="!isSubscribed" (click)="showUpgradeModal()">
                    Upgrade
                </button>
            </ng-container> -->
        </div>
    </div>
    <div class="layout__upgrade-banner" *ngIf="canShowUpgradeBanner()">
        <div class="layout__upgrade-banner__content">{{ upgradeBannerText }}</div>
        <button class="button-v2 --upgrade-btn --small --purple --rounded" (click)="showUpgradeModal()">
            {{ isSubscribed ? 'Upgrade' : 'Subscribe' }}
        </button>
    </div>
</div>
