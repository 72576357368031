export enum PaymentProviderType {
    NativeStore = 'native-store',
    Stripe = 'stripe',
}

export interface ProductPrice {
    amount: number;
    currency: string;
    currencyCode: string;
}

export interface ProductPeriod {
    inMonths: number;
    text: string;
}

export interface ProductDiscount {
    activePeriod: {
        startDate: string;
        endDate: string;
    };
    discountTitle: string;
    discountDescription: string;
    promotionTitle: string;
    couponId?: string;
    regularPrice?: ProductPrice; //Only for stripe
    maxActivationCount?: number; 
}

export interface StoreSubscriptionProduct {
    __comment__?: string;
    isEnabled: boolean;
    id: string;
    price?: ProductPrice;
    period?: ProductPeriod;
    title?: string;
    featuredTitle?: string;
    description?: string;
    isBestValue?: boolean;
    discount?: ProductDiscount;
    retailPriceProductId?: string;
}

export interface StoreLifetimeProduct {
    __comment__?: string;
    isEnabled: boolean;
    id: string;
    price?: ProductPrice;
    title?: string;
    featuredTitle?: string;
    description: string;
    isBestValue?: boolean;
    retailPriceProductId?: string;
}

export interface StoreSubscriptionTier {
    tierId: string;
    tierName: string;
    tierType: string; // 'LIMITED | FULL'
    tierDescription: string;
    contentIncluded: Record<string, string>[];
    isBestValue?: boolean;
    products: StoreSubscriptionProduct[];
    isActive: boolean;
}

interface BaseStoreGiftProduct {
    __comment__?: string;
    isEnabled: boolean;
    id: string;
    price?: ProductPrice;
    title: string;
    isBestValue: boolean;
    isLifetime: boolean;
}

interface StoreGiftDurationProduct extends BaseStoreGiftProduct {
    isLifetime: false;
    durationDays: number;
}

interface StoreGiftLifetimeProduct extends BaseStoreGiftProduct {
    isLifetime: true;
}

export type StoreGiftProduct = StoreGiftDurationProduct | StoreGiftLifetimeProduct;

export interface Product {
    id: string;
    tierId?: string;
    price?: ProductPrice;
}

export interface StoreExtraTimeProduct extends Product {
    id: string;
    price?: ProductPrice;
    hours: number;
}

export interface BookPurchase {
    bookSaleProducts: Product[];
    bookRentalProducts: Product[];
}

export interface ConsumableProducts {
    extraTimeProducts: StoreExtraTimeProduct[];
    bookPurchases: BookPurchase;
}

export interface SubscripionPromotion {
    title: string;
    activePeriod: {
        startDate: string;
        endDate: string;
    };
}

export interface NativeStoreConfig {
    consumableProducts: ConsumableProducts;
    // extraTimeProducts: StoreExtraTimeProduct[];
    giftProducts: StoreGiftProduct[];
    lifetimeProducts: StoreLifetimeProduct[];
    oldSubscriptionProductIds: string[];
    storeAndroidURL: string;
    storeIOSURL: string;
    subscriptionProducts: StoreSubscriptionProduct[];
    subscriptionUpgradeProducts: StoreSubscriptionProduct[];
    validatorURL: string;
    subscriptionPromotion?: SubscripionPromotion;
    limitedContentCohort?: LimitedContentCohort;
}

export interface StripeStoreConfig {
    publishableKey: string;
    consumableProducts: ConsumableProducts;
    subscriptionProducts: Required<Omit<StoreSubscriptionProduct, '__comment__'>>[];
    subscriptionUpgradeProducts: Required<Omit<StoreSubscriptionProduct, '__comment__'>>[];
    lifetimeProducts: Required<Omit<StoreLifetimeProduct, '__comment__'>>[];
    giftProducts: StoreGiftProduct[];
    // extraTimeProducts: StoreExtraTimeProduct[];
    subscriptionPromotion?: SubscripionPromotion;
    limitedContentCohort?: LimitedContentCohort;
}

export interface StoreEnvironment {
    isEnabled: boolean;
    native: NativeStoreConfig;
    stripe: StripeStoreConfig;
}

export interface LimitedContentCohort {
    disclaimer?: string;
    description?: string;
    tiers: Required<Omit<StoreSubscriptionTier, '__comment__'>>[];
}

export interface Environment {
    production: boolean;
    bugfenderAppKey: string;
    googleOAuthClientId: string;
    permissionsURL: string;
    privacyPolicyURL: string;
    termsOfServiceURL: string;
    twilioTokenURL: string;
    uploadBooksTutorialURL: string;
    purchaseFaqsURL: string;
    giftUrlPrefix: string;
    store: StoreEnvironment;
    firebaseConfig: {
        apiKey: string;
        authDomain: string;
        databaseURL: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId: string;
        measurementId: string;
    };
    mixpanelConfig: {
        token: string;
    };
    revenueCat: {
        apiKey: string;
    };
    singularConfig: {
        sdkKey: string;
        sdkSecret: string;
        webProductId: string;
        androidProductId: string;
    };
    deepArConfig: {
        licenseKey: string;
    };
    vonageConfig: {
        applicationId: string;
    };
    openObserveConfig: {
        deviceLogsURL: string;
        logIngestionAuthorizationHeader: string;
    };
}
