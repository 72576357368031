<div class="modal-clean modal-clean--ultra-wide-xl modal-upgrade-tier --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title">Upgrade</div>
        </div>
        <!-- <div class="modal-upgrade-tier__discount-banner" *ngIf="!isSubscribed && subscriptionPromotion?.title">
            <div class="modal-upgrade-tier__discount-banner__content">{{ subscriptionPromotion.title }}</div>
        </div> -->
        <div class="modal-upgrade-tier__description">
            {{ limitedContentCohortConfig?.description }}
        </div>

        <div class="modal-upgrade-tier__container --tablet" *ngIf="!isSubscribed">
            <div class="modal-upgrade-tier__plans" [ngClass]="{ '--has-promo': hasPromotionForTiers() }">
                <ng-container *ngFor="let tier of tiers; index as i">
                    <div
                        class="modal-upgrade-tier__plans__container"
                        [ngClass]="{ '--show-promo': getDiscountProductConfig(tier) }">
                        <div class="modal-upgrade-tier__plans__container__promo-title">
                            <img src="/assets/icons/svgs/coupon-pct.svg" />
                            <span *ngIf="getDiscountProductConfig(tier)">{{
                                getDiscountProductConfig(tier)?.promotionTitle
                            }}</span>
                        </div>
                        <div
                            class="plan-info-box"
                            [ngClass]="{ '--premium': tier.tierType === 'FULL' }"
                            (click)="selectTier(tier)">
                            <div class="plan-info-box__plan-header">
                                <div class="plan-info-box__plan-header__title">
                                    {{ tier.tierName }}
                                </div>
                                <div class="plan-period-toggle" *ngIf="tier.products?.length == 2">
                                    <ng-container *ngFor="let product of tier.products; i as index">
                                        <div
                                            class="toggle-item"
                                            (click)="selectProduct(tier, product)"
                                            [ngClass]="{ '--selected': product === tier?.productToShowAsSelected }">
                                            {{ product.title }}
                                            <span class="__saving" *ngIf="product?.savingPercentage">
                                                -{{ product?.savingPercentage }}</span
                                            >
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="price-box" *ngIf="tier.products?.length == 1">
                                    <span
                                        class="price-box__regular-price"
                                        *ngIf="tier?.productToShowAsSelected?.discountConfig"
                                        >{{ tier?.productToShowAsSelected?.discountConfig?.regularPrice }}</span
                                    >
                                    <span class="price-box__value"> {{ tier?.productToShowAsSelected?.price }} </span>
                                    <span class="price-box__period">/{{ tier?.productToShowAsSelected?.period }}</span>
                                </div>
                            </div>
                            <div class="plan-info-box__plan-sub-header">
                                <div
                                    class="plan-info-box__plan-sub-header__info"
                                    [ngClass]="{ '--link': tier.tierType === 'LIMITED' }">
                                    <ng-container *ngIf="tier.tierType === 'LIMITED'">
                                        <span (click)="showContentIncludedModal(tier)"
                                            >See content <fa-icon [icon]="['tg', 'chevron-right-solid']"></fa-icon
                                        ></span>
                                    </ng-container>
                                </div>
                                <div class="price-box" *ngIf="tier.products?.length == 2">
                                    <span
                                        class="price-box__saving"
                                        *ngIf="tier?.productToShowAsSelected?.projectedStrikethroughPrice">
                                        {{ tier?.productToShowAsSelected?.projectedStrikethroughPrice }}
                                    </span>
                                    <span class="price-box__value"> {{ tier?.productToShowAsSelected?.price }} </span>
                                    <span class="price-box__period">/{{ tier?.productToShowAsSelected?.period }}</span>
                                </div>
                            </div>
                            <div class="plan-info-box__features">
                                <div class="plan-info-box__features__item" *ngFor="let content of tier.contentIncluded">
                                    <div class="plan-info-box__features__item__count">{{ content.value }}</div>
                                    <div class="plan-info-box__features__item__text">{{ content.label }}</div>
                                </div>
                            </div>
                            <div class="plan-info-box__action-container" *ngIf="!disablePurchase">
                                <button
                                    class="button-v2 --light-purple --wide --rounded"
                                    (click)="purchase(tier.productToShowAsSelected)"
                                    [ngClass]="{ '--selected': selectedTier.tierId === tier.tierId }">
                                    {{
                                        tier?.productToShowAsSelected?.discountConfig
                                            ? tier?.productToShowAsSelected.discountConfig.discountTitle
                                            : 'Subscribe for ' +
                                              tier?.productToShowAsSelected?.price +
                                              '/' +
                                              tier?.productToShowAsSelected?.period
                                    }}
                                </button>
                                <div
                                    class="plan-info-box__action-container__discount-desc"
                                    *ngIf="tier?.productToShowAsSelected?.discountConfig">
                                    {{ tier?.productToShowAsSelected?.discountConfig.discountDescription }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="modal-upgrade-tier__purchase-instruction" *ngIf="disablePurchase">
                Upgrade in your settings once you’ve finished your call!
            </div>
            <div class="modal-upgrade-tier__terms">
                <span class="--heading">Terms & conditions:</span> {{ limitedContentCohortConfig?.disclaimer }}
            </div>
        </div>
        <div class="modal-upgrade-tier__container --mobile" *ngIf="!isSubscribed">
            <div class="modal-upgrade-tier__plans" [ngClass]="{ '--has-promo': hasPromotionForTiers() }">
                <div
                    *ngFor="let tier of tiers; index as i"
                    class="plan-info-box"
                    [ngClass]="{ '--premium': tier.tierType === 'FULL' }"
                    (click)="selectTier(tier)">
                    <div class="plan-info-box__plan-sub-header">
                        <div class="plan-info-box__plan-sub-header__info">{{ tier.tierDescription }}</div>
                    </div>
                    <div
                        class="plan-info-box__container"
                        [ngClass]="{ '--show-promo': getDiscountProductConfig(tier) }">
                        <div class="plan-info-box__promo-title" *ngIf="getDiscountProductConfig(tier)">
                            <img src="/assets/icons/svgs/coupon-pct.svg" />
                            <span *ngIf="getDiscountProductConfig(tier)">{{
                                getDiscountProductConfig(tier)?.promotionTitle
                            }}</span>
                        </div>

                        <div
                            class="plan-info-box__plan-header"
                            [ngClass]="{
                                '--selected': selectedTier.tierId == tier.tierId,
                                '--large-price-text': getCharacterCount(tier?.productToShowAsSelected?.price) > 8
                            }">
                            <div class="plan-info-box__plan-header__title">
                                {{ tier.tierName }}
                            </div>
                            <div class="price-box --horizontal">
                                <span
                                    class="price-box__saving"
                                    *ngIf="tier?.productToShowAsSelected?.projectedStrikethroughPrice">
                                    {{ tier?.productToShowAsSelected?.projectedStrikethroughPrice }}
                                </span>
                                <span
                                    class="price-box__regular-price"
                                    *ngIf="tier?.productToShowAsSelected?.discountConfig && tier.products?.length == 1"
                                    >{{ tier?.productToShowAsSelected?.discountConfig?.regularPrice }}</span
                                >
                                <span class="price-box__value"> {{ tier?.productToShowAsSelected?.price }} </span>
                                <span class="price-box__period">/{{ tier?.productToShowAsSelected?.period }}</span>
                            </div>
                            <div class="price-box --vertical">
                                <div>
                                    <span class="price-box__value"> {{ tier?.productToShowAsSelected?.price }} </span>
                                    <span class="price-box__period">/{{ tier?.productToShowAsSelected?.period }}</span>
                                </div>
                                <span
                                    class="price-box__saving"
                                    *ngIf="tier?.productToShowAsSelected?.projectedStrikethroughPrice">
                                    {{ tier?.productToShowAsSelected?.projectedStrikethroughPrice }}
                                </span>
                                <span
                                    class="price-box__regular-price"
                                    *ngIf="tier?.productToShowAsSelected?.discountConfig && tier.products?.length == 1">
                                    {{ tier?.productToShowAsSelected?.discountConfig?.regularPrice }}
                                </span>
                            </div>
                            <div class="plan-checkbox">
                                <span
                                    *ngIf="selectedTier.tierId != tier.tierId"
                                    class="plan-checkbox__unchecked"></span>
                                <fa-icon
                                    *ngIf="selectedTier.tierId == tier.tierId"
                                    [icon]="['tg', 'checked-round']"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="features-box">
                    <ng-container *ngFor="let tier of tiers; index as i">
                        <div
                            class="features-box__features"
                            [ngClass]="{
                                '--selected': selectedTier.tierId == tier.tierId,
                                '--premium': tier.tierType === 'FULL'
                            }">
                            <div class="features-box__features__heading">
                                <div class="features-box__features__heading__text">
                                    {{ selectedTier.tierName }} includes:
                                </div>
                                <ng-container *ngIf="tier.tierType === 'LIMITED'">
                                    <div
                                        class="features-box__features__heading__link"
                                        (click)="showContentIncludedModal(tier)">
                                        <span>
                                            See content <fa-icon [icon]="['tg', 'chevron-right-solid']"></fa-icon>
                                        </span>
                                    </div>
                                </ng-container>
                                <!--  <ng-container *ngIf="tier.tierType === 'FULL'">
                                    <div class="features-box__features__heading__link">Get Everything!</div>
                                </ng-container> -->
                            </div>
                            <ng-container *ngFor="let content of tier.contentIncluded">
                                <div class="features-box__features__item">
                                    <div class="features-box__features__item__count">{{ content.value }}</div>
                                    <div class="features-box__features__item__text">{{ content.label }}</div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="showcase-footer">
                <div class="plan-period-toggle" *ngIf="selectedTier && selectedTier.products?.length == 2">
                    <ng-container *ngFor="let product of selectedTier.products; i as index">
                        <div
                            class="toggle-item"
                            (click)="selectProduct(selectedTier, product)"
                            [ngClass]="{ '--selected': product === selectedTier.productToShowAsSelected }">
                            {{ product.title }}
                            <span class="__saving" *ngIf="product?.savingPercentage">
                                -{{ product?.savingPercentage }}</span
                            >
                        </div>
                    </ng-container>
                </div>
                <div class="showcase-footer__action-container" *ngIf="!disablePurchase">
                    <button
                        *ngIf="selectedTier"
                        class="button-v2 --purple --rounded --medium --wide"
                        (click)="purchase(selectedTier.productToShowAsSelected)">
                        {{
                            selectedTier?.productToShowAsSelected?.discountConfig
                                ? selectedTier?.productToShowAsSelected.discountConfig.discountTitle
                                : 'Subscribe for ' +
                                  selectedTier?.productToShowAsSelected?.price +
                                  '/' +
                                  selectedTier?.productToShowAsSelected?.period
                        }}
                    </button>
                    <div
                        class="showcase-footer__action-container__discount-desc"
                        *ngIf="selectedTier?.productToShowAsSelected?.discountConfig">
                        {{ selectedTier?.productToShowAsSelected?.discountConfig.discountDescription }}
                    </div>
                </div>
                <div class="showcase-footer__purchase-instruction" *ngIf="disablePurchase">
                    Upgrade in your settings once you’ve finished your call!
                </div>
                <div class="showcase-footer__terms">
                    <span class="--heading">Terms & conditions: </span> {{ limitedContentCohortConfig?.disclaimer }}
                </div>
            </div>
        </div>
    </div>
</div>
