import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    UserFavoritesModel,
    ActivityModel,
    BookModel,
    GetFavoritesInteractor,
    UserModel,
    FavoritesFilter,
} from '@together/common';
import { Subscription } from 'rxjs';

import {
    SpringboardActivitySelection,
    SpringboardBookSelection,
    SpringboardSection,
    SpringboardSelection,
    SpringboardState,
} from '../../springboard.component';
import { SpringboardService } from '@app/shared/services/springboard.service';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'app-section-favorites',
    templateUrl: './section-favorites.component.html',
    styleUrls: ['./section-favorites.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionFavoritesComponent implements OnChanges {
    @Input() public section: SpringboardSection;
    @Input() public users: UserModel[];
    @Input() public favoritesState: SpringboardState;

    @Output() public selected = new EventEmitter<SpringboardSelection>();
    @Output() public stateChanged = new EventEmitter<SpringboardState>();

    public isLoading = true;
    public favorites: UserFavoritesModel;
    public animation: AnimationOptions = {
        path: './assets/animations/loading.json',
    };
    public showAllFavoritesModal = false;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly getFavorites: GetFavoritesInteractor,
        private readonly springboard: SpringboardService,
    ) {}

    public ngOnChanges(changes): void {
        if (changes.hasOwnProperty('users')) {
            const hasUsersInput = typeof this.users !== 'undefined' && this.users !== null;
            if (hasUsersInput) {
                this.load(true);
            }
        }

        if (changes.hasOwnProperty('favoritesState')) {
            if (this.favoritesState?.showAll) {
                this.section = this.favoritesState.section;
                this.showAllFavoritesModal = true;
            }
        }
    }

    public emitSelect(item: unknown, section): void {
        switch (section) {
            case SpringboardSection.Activities:
            case SpringboardSection.Games:
            case SpringboardSection.Topics:
                return this.selected.emit(new SpringboardActivitySelection(item as ActivityModel));

            case SpringboardSection.Books:
                return this.selected.emit(new SpringboardBookSelection(item as BookModel));
        }
    }

    public showAllFavorites(section) {
        this.section = section;
        this.showAllFavoritesModal = true;
        this.favoritesState = {
            section,
            isFiltered: false,
            filters: {},
            showAll: true,
        };
        this.stateChanged.emit(this.favoritesState);
    }

    private async load(refreshList = false): Promise<void> {
        this.isLoading = true;
        this.cdr.markForCheck();
        const filter: FavoritesFilter = {
            includeBooks: true,
            includeActivities: true,
        };
        this.favorites = await this.getFavorites.execute(filter, refreshList);

        this.isLoading = false;
        this.cdr.markForCheck();
    }

    public checkIfContentIsRestricted(content: BookModel | ActivityModel): boolean {
        //Content is restricted only if both users have restricted access
        return this.users?.every(u => u.isContentRestricted(content));
    }

    public close() {
        this.showAllFavoritesModal = false;
        this.favoritesState = null;
        this.stateChanged.emit(this.favoritesState);
    }
}
