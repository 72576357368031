import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import {
    GetTutorialsByPlatformInteractor,
    ToggleFavoriteActivityInteractor,
    ToggleFavoriteBookInteractor,
    TutorialModel,
    UserModel,
    UserService,
} from '@together/common';
import { SpringBoardActionTypes, SpringboardBookPurchased } from './../../services/springboard.service';

import {
    SpringboardSection,
    SpringboardState,
    TogglefavoriteItem,
    ToggleFavouriteAction,
} from '@app/shared/components/springboard/springboard.component';
import { ModalsService } from '@app/shared/services/modals.service';
import { SpringboardService } from '@app/shared/services/springboard.service';
import { IStoreService } from '@app/shared/services/store/store-service.interface';
import { ActivityInfoModalComponent } from '@app/web/modals/activity-info-modal/activity-info-modal.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { FeedbackModalComponent } from '../../../web/modals/feedback-modal/feedback-modal.component';
import {
    SpringboardActivitySelection,
    SpringboardBookSelection,
    SpringboardSelection,
} from '../springboard/springboard.component';

@Component({
    selector: 'app-springboard-home',
    templateUrl: './springboard-home.component.html',
    styleUrls: ['./springboard-home.component.scss'],
})
export class SpringboardHomeComponent implements OnInit {
    public feedbackModel: TutorialModel;
    public tutorials: TutorialModel[] | boolean = false;
    public users: UserModel[];
    public springboardState: SpringboardState = {
        section: SpringboardSection.Books,
        isFiltered: false,
        filters: {},
    };

    private subs = new Subscription();
    private bookPurchasedState: SpringboardBookPurchased;
    constructor(
        protected getTutorialsByPlatform: GetTutorialsByPlatformInteractor,
        protected modalService: SimpleModalService,
        protected modals: ModalsService,
        protected router: Router,
        @Inject('StoreService') protected storeService: IStoreService,
        protected userService: UserService,
        private readonly toggleFavoriteActivity: ToggleFavoriteActivityInteractor,
        private readonly toggleFavoriteBook: ToggleFavoriteBookInteractor,
        private readonly springboard: SpringboardService,
    ) {
        const current = this.router.getCurrentNavigation();
        const hasState = current && current.extras && current.extras.state;
        const state = hasState ? current.extras.state : history.state;
        if (state.springboardState) {
            // This `springboardState` comes from `router.navigate` extra state parameters from book-preview to load the filtered see more view, see: `book-preview.component.ts`
            // This `springboardState` comes from `router.navigate` extra state parameters from app-layout to retain state
            this.springboardState = state.springboardState;
        }
        if (state.bookPurchasedState) {
            // This `reload` comes from `router.navigate` extra state parameters from book-preview to reload the springboard after purchase see: `book-preview.component.ts`
            this.bookPurchasedState = state.bookPurchasedState;
        }
    }

    public async ngOnInit(): Promise<void> {
        if (await this.userService.isLoggedIn()) {
            const currentUser = await this.userService.getUser();
            this.users = [currentUser]; // User list for the springboard
        } else {
            this.users = [];
        }

        this.tutorials = await this.getTutorialsByPlatform.execute();
        this.feedbackModel = this.getFeedbackModelData();

        this.subs.add(
            this.springboard.actions$().subscribe(action => {
                switch (action.type) {
                    case SpringBoardActionTypes.RefreshUser:
                        this.users = [action.value];
                        break;
                    default:
                        break;
                }
            }),
        );
        this.subs.add(
            this.storeService.getSubscriptionState$().subscribe(async state => {
                const currentUser = await this.userService.getUser();
                this.users = [currentUser]; // User list for the springboard
            }),
        );
        this.subs.add(
            this.userService.getUser$().subscribe(user => {
                this.users = [user];
            }),
        );
        setTimeout(() => {
            if (this.bookPurchasedState) {
                this.springboard.bookPurchased(
                    this.bookPurchasedState.value,
                    this.bookPurchasedState.purchaseType,
                    this.bookPurchasedState.showSuccessModal,
                );
            }
        }, 10);
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    protected getFeedbackModelData(): TutorialModel {
        const feedback = new TutorialModel();

        feedback.iconURL = 'https://bitwisesl.com/together/tutorials/icons/FeedbackIcon.png';
        feedback.title = 'Give us feedback!';

        return feedback;
    }

    public showTutorialModal(tutorial: TutorialModel): void {
        this.modals.showWebview(tutorial.title, tutorial.itemURL);
    }

    public showFeedbackModal(): void {
        this.modalService.addModal(FeedbackModalComponent);
    }

    public springboardSelected(selection: SpringboardSelection): void {
        if (selection instanceof SpringboardActivitySelection) {
            this.modalService.addModal(ActivityInfoModalComponent, {
                activity: selection.activity,
            });
        } else if (selection instanceof SpringboardBookSelection) {
            this.router.navigate(['/book'], {
                queryParams: { bookId: selection.book.id },
                state: { book: selection.book, springboardState: this.springboardState },
            });
        }
        // V2.0 - this is not applicable as we do not have a catalog.
        // else if (selection instanceof SpringboardShowAllBooks) {
        //     this.router.navigate(['/books']);
        // } else if (selection instanceof SpringboardShowAllGames) {
        //     this.router.navigate(['/activities'], {
        //         queryParams: { category: 'game' },
        //     });
        // } else if (selection instanceof SpringboardShowAllLearningActivities) {
        //     this.router.navigate(['/activities'], {
        //         queryParams: { category: 'learn' },
        //     });
        // }
    }

    public async toggleFavorite(item: TogglefavoriteItem): Promise<void> {
        if (item.action === ToggleFavouriteAction.UnFavourite) {
            this.modals.showUnfavorite(item);
        } else {
            switch (item.type) {
                case 'learn':
                case 'game':
                    await this.toggleFavoriteActivity.execute(this.users[0], item.value.id);
                    break;

                case 'book':
                    await this.toggleFavoriteBook.execute(this.users[0], item.value.id);
                    break;
            }
        }
    }

    public showUpgradeModal(): void {
        this.storeService.showUpgradeModal();
    }

    public stateChanged(event) {
        this.springboardState = event;
        this.springboard.stateChanged(this.springboardState);
    }
}
